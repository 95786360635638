import Cookies from "js-cookie";
import { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { useJwt } from "react-jwt";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./Header.css";


const Header = () => {
    const params = useParams();
    const {isExpired} = useJwt(Cookies.get('token'));
    
    const history = useNavigate();
    const [clicked, setClicked] = useState(false);

    const handleClick = () => {
        setClicked(!clicked);
    }

    const doLogout = () => {
        Cookies.remove('token');
        history('/login');
    }

    return (
        <>
            <nav>
                <button onClick={handleClick} className="menu-icon">
                    {clicked
                        ? <FaTimes size={20} />
                        : <FaBars size={20} />
                    }
                </button>
                <ul>
                    <div className={clicked ? 'nav-menu active' : 'nav-menu'}>
                        {!isExpired ?
                            <>
                                <li><Link to={"/" + params.Kawasanid + "/produk"} onClick={handleClick}>Produk</Link></li>
                                <li><Link to={"/" + params.Kawasanid + "/testimoni"} onClick={handleClick}>Testimoni</Link></li>
                                <li><Link to={"/" + params.Kawasanid + "/booking"} onClick={handleClick}>Booking</Link></li>
                                <li onClick={doLogout}>Logout</li>
                            </>
                            :
                            <>
                                <li><Link to="/login">Login</Link></li>
                            </>
                        }
                    </div>


                </ul>
            </nav>
        </>
    );
};

export default Header;