import "../produk.css";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Api from "../../../controller/ProdukController";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

const EditProduk = () => {
    const { id } = useParams();
    const history = useNavigate();
    const [error, setError] = useState(null);
    const [produk, setProduk] = useState(null);
    const [errorGet, setErrorGet] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        Api.getSingle(id)
            .then((res) => {
                setProduk(res?.data);
                console.log(produk);
            })
            .catch((error) => {
                console.log(error);
                setErrorGet(error.message);
            })
            .finally(() => {
                setLoading(false);
            })
        // eslint-disable-next-line
    }, [produk?.code]);

    const doEdit = (values) => {
        console.log(values);
        Api.put(values, id)
            .then((res) => {
                console.log(res);
                history("/");
            })
            .catch((error) => {
                console.log(error);
                setError(error.message);
            })
            .finally(() => {
                formik.setSubmitting(false);
            })
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: produk ? produk?.data?.name : "",
            type: produk ? produk?.data?.type : "",
            price: produk ? produk?.data?.price : 0,
            field: produk ? produk?.data?.field : 0,
            lot_number: produk ? produk?.data?.lot_number : 0,
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required('Tidak boleh kosong'),
            field: Yup.number()
                .required('Tidak boleh kosong')
                .typeError('Input tidak valid'),
            type: Yup.string()
                .required('Tidak boleh kosong'),
            price: Yup.number()
                .required('Tidak boleh kosong')
                .typeError('Input tidak valid'),
            lot_number: Yup.number()
                .required('Tidak boleh kosong')
                .typeError('Input tidak valid'),
        }),
        onSubmit: doEdit,
    });

    return (
        <>
            {loading && <p>loading</p>}
            {errorGet && <p>error</p>}
            {produk &&
                <div className="p-menu">
                    <h1>Edit Produk</h1>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="f-group c-width">
                            <label>Nomor Kav</label>
                            <input
                                type="number"
                                name="lot_number"
                                min={0}
                                {...formik.getFieldProps('lot_number')}
                            />
                            {formik.touched.lot_number && formik.errors.lot_number && <div className="error">{formik.errors.lot_number}</div>}
                        </div>

                        <div className="f-group c-width">
                            <label>Nama</label>
                            <input
                                type="text"
                                name="name"
                                {...formik.getFieldProps('name')}
                            />
                            {formik.touched.name && formik.errors.name && <div className="error">{formik.errors.name}</div>}
                        </div>

                        <div className="f-group c-width">
                            <label>Tipe</label>
                            <select
                                name="type"
                                {...formik.getFieldProps('type')}
                            >
                                <option value="" disabled hidden>Pilih Tipe</option>
                                <option value="KAV">KAV</option>
                                <option value="TIPE 50">TIPE 50</option>
                            </select>
                            {formik.touched.type && formik.errors.type && <div className="error">{formik.errors.type}</div>}
                        </div>

                        <div className="f-group c-width">
                            <label>Luas Kav</label>
                            <input
                                type="number"
                                name="field"
                                min={0}
                                step="any"
                                {...formik.getFieldProps('field')}
                            />
                            {formik.touched.field && formik.errors.field && <div className="error">{formik.errors.field}</div>}
                        </div>

                        <div className="f-group c-width">
                            <label>Harga</label>
                            <input
                                type="number"
                                name="price"
                                step="any"
                                {...formik.getFieldProps('price')}
                            />
                            {formik.touched.price && formik.errors.price && <div className="error">{formik.errors.price}</div>}
                        </div>

                        <button type="submit" className="btn c-width">Edit</button>
                        {error && <div className="error">{error}</div>}
                    </form>
                </div>
            }
        </>
    );
};

export default EditProduk;