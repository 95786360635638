import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from 'formik';
import Api from "../../../controller/BookingController";
import Home from "../../../controller/ProdukController";

const DetailBooking = () => {
    const { id, Kawasanid } = useParams();
    const [data, setData] = useState(null);
    const [home, setHome] = useState(null);
    const navigate = useNavigate();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            address: data?.data?.address || "",
            email: data?.data?.email || "",
            firstName: data?.data?.firstName || "",
            homeId: data?.data?.homeId || null,
            idCardNumber: data?.data?.idCardNumber || "",
            lastName: data?.data?.lastName || "",
            phoneNumber: data?.data?.phoneNumber || 0,
            isAccepted: data?.data?.isAccepted 
        }
    })

    useEffect(() => {
        Home.get()
            .then((res) => {
                setHome(res.data);
            })
            .catch((error) => {
                console.log(error);
            })
        // eslint-disable-next-line
    }, [home?.status]);

    useEffect(() => {
        Api.getSingle(id)
            .then((res) => {
                setData(res?.data);
            })
            .catch((error) => {
                console.log(error);
            })
        // eslint-disable-next-line
    }, [data?.status]);


    const updateApi = () => {
        Api.put(formik.values, id)
        .then(()=>{
            navigate(`/${Kawasanid}/booking`);
        })
        .catch((error)=>{
            console.log(error);
        })
    }

    const handlerUpdate= () => {
        updateApi();
    }

    const handlerButton = (value) => {
        formik.values.isAccepted = value;
        updateApi();
    }

    const onSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <div>
            <h1>Detail Booking</h1>
            <form onSubmit={onSubmit}>
                <div>
                    <label>Nama Depan</label>
                    <input
                        type="text"
                        {...formik.getFieldProps('firstName')}
                        disabled />
                </div>
                <div>
                    <label>Nama belakang</label>
                    <input
                        type="text"
                        {...formik.getFieldProps('lastName')}
                        disabled />
                </div>
                <div>
                    <label>Home ID</label>
                    <select
                        {...formik.getFieldProps('homeId')}
                        disabled={data?.data?.homeId ? true : false}
                    >
                        {data?.data?.homeId
                            ?
                            <option value={formik.values.homeId}>
                                {home?.data.filter(item => item.id === formik.values.homeId)[0]?.name || formik.values.homeId}
                            </option>
                            :
                            <>
                                <option value={0} hidden>Pilih Home</option>
                                {home?.data.map((item, index) => (
                                    <option key={index} value={item.id}>{item.name}</option>
                                ))}
                            </>
                        }

                    </select>
                </div>
                <div>
                    <label>email</label>
                    <input
                        type="email"
                        {...formik.getFieldProps('email')}
                        disabled />
                </div>
                <div>
                    <label>Nomor Kartu Identitas</label>
                    <input
                        type="text"
                        {...formik.getFieldProps('idCardNumber')}
                        disabled />
                </div>
                <div>
                    <label>Nomor HP</label>
                    <input
                        type="text"
                        {...formik.getFieldProps('phoneNumber')}
                        disabled />
                </div>
                <div className="btn-group">
                    {!data?.data?.homeId && <button onClick={handlerUpdate}>Update Home ID</button>}
                    {formik.values.isAccepted == null ?
                        <>
                            <button onClick={()=>handlerButton(true)}>Terima</button>
                            <button onClick={()=>handlerButton(false)}>Tolak</button>
                        </>
                        : null
                    }
                </div>
            </form>
        </div>
    );
};

export default DetailBooking;