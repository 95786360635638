import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Api from "../../../controller/BookingController";
import "./MenuBooking.css";


const MenuBooking = () => {
    const params = useParams();
    const [book, setBook] = useState(null);
    const [filter, setFilter] = useState(null)
    const navigate = useNavigate();

    useEffect(() => {
        Api.get()
            .then((res) => {
                setBook(res?.data);
                setFilter(book?.data.filter(item => item.isAccepted === null));
            })
        // eslint-disable-next-line
    }, [book?.status]);

    const handlerNav = (nav) => {
        if (nav === 1) {
            setFilter(book?.data.filter(item => item.isAccepted === null));
        }
        if (nav === 2) {
            setFilter(book?.data.filter(item => item.isAccepted === true));
        }

        if (nav === 3) {
            setFilter(book?.data.filter(item => item.isAccepted === false));
        }
    }

    const handlerClick = (id) => {
        navigate(`/${params.Kawasanid}/booking/${id}`)
    }

    return (
        <div className="b-menu">
            <h1>Booking</h1>
            <div>
                <ul>
                    <li><button onClick={() => handlerNav(1)} className="btn-head">Proses</button></li>
                    <li><button onClick={() => handlerNav(2)} className="btn-head">Selesai</button></li>
                    <li><button onClick={() => handlerNav(3)} className="btn-head">Ditolak</button></li>
                </ul>
            </div>
            <div className="b-content" style={{ display: "grid" }}>
                {filter &&
                    <div>
                        {filter
                            .map((item, index) => (
                                <div
                                    onClick={() => handlerClick(item?.id)}
                                    key={index}
                                    className="c-width b-card"
                                    style={{ margin: "20px auto" }}>
                                    <p>{item?.firstName} {item?.lastName}</p>
                                    <p>{item?.phoneNumber}</p>
                                </div>
                            ))}
                    </div>
                }
            </div>
        </div>
    );
};

export default MenuBooking;