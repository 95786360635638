import "./Login.css";
import { Logo } from "../../assets";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Api from "../../controller/LoginController";
import Cookies from "js-cookie";

const Login = () => {
    const history = useNavigate();
    const [failed, setFailed] = useState(null);

    const doLogin = (values) => {
        Api.post(values)
            .then((res) => {
                Cookies.set('token', res?.data?.data?.token, { expires: 1 });
                history("/");
            })
            .catch((error) => {
                setFailed(error.message);
            })
            .finally(() => {
                formik.setSubmitting(false);
                formik.resetForm();
            })
    };

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        validationSchema: Yup.object({
            username: Yup.string()
                .required('Tidak boleh kosong'),
            password: Yup.string()
                .required('Tidak boleh kosong')
        }),
        onSubmit: doLogin
    })

    return (
        <div className="login">
            <h1>Login Page</h1>
            <img src={Logo} width={100} height={100} alt="Logo" />
            <form onSubmit={formik.handleSubmit}>
                <div className="f-group">
                    <label>Username</label>
                    <input
                        type="text"
                        name="username"
                        {...formik.getFieldProps('username')}
                    />
                    {formik.touched.username && formik.errors.username && <div className="error">{formik.errors.username}</div>}
                </div>

                <div className="f-group">
                    <label>Password</label>
                    <input
                        type="password"
                        name="password"
                        {...formik.getFieldProps('password')}
                    />
                    {formik.touched.password && formik.errors.password && <div className="error">{formik.errors.password}</div>}
                </div>

                <button type="submit" className="btn">Login</button>
                {failed && <div className="error">Username / Password salah</div>}
            </form>
        </div>
    );
}

export default Login;