import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PCard from "../../../components/PCard/PCard";
import Api from "../../../controller/ProdukController";

const MenuProduk = () => {
    const [produk, setProduk] = useState([]);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    // const [page, setPage] = useState(1);
    // const [morePage, setMorePage] = useState(true);

    useEffect(() => {
        Api.get()
            .then((res) => {
                setProduk(res?.data);
                // setProduk(p => [...p, ...res.data.data]);
                // if (res.data?.meta?.page === res.data?.meta?.page_count) {
                //     setMorePage(false);
                // }
            })
            .catch((err) => {
                console.log(err);
                setError(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }, [produk?.status]);

    // const handlerNext = () => {
    //     setPage(page + 1);
    // }
    return (
        <>
            {loading && <p>Loading</p>}
            {error && <p>error</p>}
            {produk &&
                <div className="p-menu">
                    <h1>Menu Produk</h1>
                    <Link style={{ width: "fit-content", margin: "auto" }} to={"/produk/tambah"}><button className="btn-add">Tambah</button></Link>
                    <div>
                        {produk?.data?.length > 0 &&
                            <div>
                                {produk?.data?.map((item, index) => (
                                    <PCard data={item} key={index} />
                                ))}

                                {/* <button
                                    onClick={handlerNext}
                                    disabled={morePage ? false : true}
                                    className="btn-next"
                                >
                                    Selanjutnya
                                </button> */}
                            </div>
                        }
                        {
                            produk?.data?.length === 0 && <p>Produk tidak ada</p>
                        }
                    </div>
                </div>}
        </>
    );
};

export default MenuProduk;