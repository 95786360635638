import Cookies from "js-cookie";
import Api from "../api/config";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    post: (value) => Api.post(
        '/home',
        value,
        { headers: { "Authorization": "Bearer " + Cookies.get('token') } }),
    get: () => Api.get('/home'),
    // getPagination: (page) => Api.get(`/home?page=${page}`),
    put: (value, id) => Api.put(
        `/home/${id}`,
        value,
        { headers: { "Authorization": "Bearer " + Cookies.get('token') } }),
    getSingle: (id) => Api.get(`/home/${id}`),
    delete: (id) => Api.delete(`/home/${id}`, { headers: { "Authorization": "Bearer " + Cookies.get('token') } })
}