import { Link, useNavigate } from "react-router-dom";
import Api from "../../controller/ProdukController";
import "./PCard.css";


const PCard = ({ data }) => {
    const navigate = useNavigate();

    const handlerDelete = () => {
        Api.delete(data?.id)
            .then((res) => {
                console.log(res);
                navigate("/");
            })
            .catch((err) => {
                console.log(err);
            })
    }
    return (
        <div className="p-card c-width">
            <div className="p-content">
                <h2>{data?.name}</h2>
                <p>{data?.type?.name}</p>
            </div>
            <div className="p-option">
                <Link to={"/produk/edit/" + data?.id}><button className="btn-edit">Edit</button></Link>
                <button className="btn-del" onClick={handlerDelete}>Delete</button>
            </div>
        </div>
    );
};

export default PCard;