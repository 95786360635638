import "./Tcard.css";
import Api from "../../controller/TestimoniController";
import { useNavigate } from "react-router-dom";

const Tcard = ({ data }) => {
    const navigate = useNavigate();

    const handlerDelete = () =>{
        Api.detele(data?.id)
        .then((res)=>{
            console.log(res);
            navigate("/")
        })
        .catch((err)=>{
            console.log(err);
        })
    }

    return (
        <div className="t-card c-width">
            <div className="t-content">
                <h2> {data?.name ? data?.name : "Anonim"}</h2>
                <p>{data?.testimonial}</p>
            </div>
            <div className="t-option">
                <button onClick={handlerDelete}>Delete</button>
            </div>

        </div>
    );
};

export default Tcard;