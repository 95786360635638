import Cookies from "js-cookie";
import { useJwt } from "react-jwt";
import { Outlet, Navigate } from "react-router-dom";

const useAuth = () => {
    const {isExpired} = useJwt(Cookies.get('token'));  

    if(!isExpired ){
        return true;
    }

    return false;
};

const ProtectedRoute = () => {
    const isAuth = useAuth();
    return isAuth ? <Outlet /> : <Navigate to="/login" replace />;
}

export default ProtectedRoute;