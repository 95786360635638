import { useEffect, useState } from "react";
import Tcard from "../../components/TCard/Tcard";
import Api from "../../controller/TestimoniController";

const Testimoni = () => {
    const [testimoni, setTestimoni] = useState(null);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        Api.get()
            .then((res) => {
                setTestimoni(res?.data);
            })
            .catch((error) => {
                setError(error.message);
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            })
        // eslint-disable-next-line
    }, [testimoni?.code]);

    return (
        <div>
            <h1>Testimoni</h1>
            {loading && <p>Loading</p>}
            {error && <p>error</p>}
            {testimoni?.data.length > 0 &&
                <div>
                    {testimoni?.data.map((item, index)=>(
                        <Tcard data={item} key={index}/>
                    ))}
                </div>
            }
            {testimoni?.data.length === 0 && <p>Tidak ada testimoni</p>}
        </div>
    );
};

export default Testimoni;