import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Header } from "./components";
import { Login } from "./pages";
import "./App.css";
import ProtectedRoute from "./ProtectedRoute";
import TambahProduk from "./pages/Produk/Tambah/TambahProduk";
import NotFound from "./pages/NotFound/NotFound";
// import Maintenance from "./pages/Maintenance/Maintenance";
import EditProduk from "./pages/Produk/Edit/EditProduk";
import MenuProduk from "./pages/Produk/Menu/MenuProduk";
import Testimoni from "./pages/Testimoni/Testimoni";
import MenuBooking from "./pages/Booking/Menu/MenuBooking";
import DetailBooking from "./pages/Booking/Detail/DetailBooking";

function App() {
  return (
    <Router>
      <div>
        <div className="app">

          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<NotFound />} />
            <Route element={<ProtectedRoute />}>
              <Route exact path="/" element={
                <div>
                  <h1>Homepage</h1>
                  <p>Login sukses!</p>
                  <Link to="1/produk">Griya Paresia</Link>
                </div>}
              />
              <Route path=":Kawasanid">
                <Route path="produk" element={<>
                  <Header />
                  <MenuProduk />
                </>} />
                <Route path="produk/tambah" element={<>
                  <Header />
                  <TambahProduk /></>} />
                <Route path="produk/edit/:id" element={<>
                  <Header />
                  <EditProduk /></>} />
                <Route path="testimoni" element={<>
                  <Header />
                  <Testimoni /></>} />
                <Route path="booking" element={<>
                  <Header />
                  <MenuBooking />
                </>} />
                <Route path="booking/:id" element={<>
                  <Header />
                  <DetailBooking /></>} />
              </Route>
            </Route>
          </Routes>
        </div>
      </div>
    </Router>
  );
}
export default App;
